<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  width: 13em;
  height: 2.5em;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
</style>
<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddEventModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="teamForm" class="pt-10">
            <v-row no-gutters>
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Category"
                  outlined
                  dense
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :items="categoryList"
                  item-value="id"
                  item-text="name"
                  v-model="event_category"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center" v-if="this.event_category == 8">
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Event Name"
                  outlined
                  dense
                  v-model="event_name"
                  :items="eventNameOptions"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              v-if="this.event_name == 'Other'"
            >
              <v-col cols="12" class="pl-2">
                <v-text-field
                  label="Event Name"
                  outlined
                  dense
                  v-model="event_name1"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-text-field>
              </v-col>
            </v-row>
             <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
             <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  label="Event Address"
                  color="#7253CF"
                  class="formFields"
                  v-model="address"
                  :disabled="formLoading"
                ></v-text-field>
              </v-col>
              </v-row>
                 <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="6" class="pl-2">
                <v-autocomplete
                  label="Event State"
                  outlined
                  dense
                  v-model="state"
                  :items="statesList"
                  class="formFields"
                  item-text="state_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-text-field
                  label="Event City"
                  outlined
                  dense
                  v-model="city"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="6" class="pl-2">
                <v-text-field
                  label="Meeting Place"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="school"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
               <v-col cols="6" class="pl-2">
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="meeting_time"
                  placeholder="Meeting Time"
                    input-width="100%"
                  close-on-complete
                     :manual-input="true"    :minute-interval="5"
                ></vue-timepicker>
                 </v-col>
                  <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="organization"
                  label="Organization"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
                 <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="6" class="pl-2">
                <v-text-field
                  label="Contact Name"
                  outlined
                  dense
                  v-model="contact_name"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
               <v-col cols="6" class="pl-2">
                <v-text-field
                  label="Phone"
                  outlined
                  dense
                  v-model="phone"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
              <v-col cols="12" class="pl-2"  v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              ">
                <v-text-field
                  label="Email"
                  outlined
                  dense
                  v-model="email"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedStartDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Start Date "
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
                 <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu"
                  v-model="menu16"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedEndDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="End Date "
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    no-title
                    @input="menu16 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <label style="font-size: 16px;font-weight: 500;">Start Time</label>
                   <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="start_time"
                  placeholder="Start Time"
                    input-width="100%"
                  close-on-complete
                     :manual-input="true"    :minute-interval="5"
                ></vue-timepicker>
              </v-col>
              <v-col cols="6" class="pl-2">
                <label style="font-size: 16px;font-weight: 500;">End Time</label>
                 <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="end_time"
                  placeholder="End Time"
                   input-width="100%"
                  close-on-complete
                     :manual-input="true"    :minute-interval="5"
                ></vue-timepicker>
              </v-col>
            </v-row>
            <br />
         
            <v-row
              no-gutters
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="6" class="pl-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team_id"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  @change="getteamPlayer()"
                >
                </v-autocomplete> </v-col
              ><v-col cols="6" class="pl-2" v-if="this.team_id == null">
                <v-combobox
                  v-model="players"
                  :items="playerList"
                  label="Player"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Sales Rep"
                  outlined
                  dense
                  v-model="sales_rep"
                  :items="salesRepList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <!--   <v-col cols="6" class="px-2">
                <v-autocomplete
                  label="Event Support"
                  outlined
                  dense
                  v-model="event_support"
                  :items="eventSupportList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>-->
            </v-row>
           
           
            <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="12" class="pl-2">
                <v-text-field
                  label="Designation"
                  outlined
                  dense
                  v-model="designation"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              v-if="
                this.event_category != 7 &&
                this.event_category != 4 &&
                this.event_category != 6
              "
            >
              <v-col cols="12" class="pl-2">
                <label>Note</label>
                <quillEditor v-model="note" :options="editorOption" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions
          class="pt-0 pb-7 px-10"
          v-if="
            this.event_category != 7 &&
            this.event_category != 4 &&
            this.event_category != 6
          "
        >
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitPaidEventForm"
          >
            <span>Add Event</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'view'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitPaidEventForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
        <v-card-text v-if="this.event_category == 4">
          <v-form lazy-validation ref="hotelInfoForm">
            <v-row no-gutters>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="hotelName"
                  label="Hotel Name"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="hotel_address"
                  label="Hotel Address"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Event State"
                  outlined
                  dense
                  v-model="state"
                  :items="statesList"
                  class="formFields"
                  item-text="state_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  label="Event City"
                  v-model="city"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team_id"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  @change="getteamPlayer()"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-2" v-if="this.team_id == null">
                <v-combobox
                  v-model="players"
                  :items="playerList"
                  label="Player"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="rate"
                  label="Rate"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="contactPersonName"
                  label="Contact Person Name"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="contact_email"
                  label="Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="phone"
                  label="Phone"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="noOfRooms"
                  label="Number of Rooms"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  type="number"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="checkInDateFormatted"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Check In Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      :rules="[rules.required]"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="checkInDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="checkOutDateFormatted"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Checkout Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      :rules="[rules.required]"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="checkOutDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pl-2">
                <v-textarea
                  outlined
                  row-height="4"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  v-model="confirmation_number"
                  label="Confirmation Number"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pl-2">
                <label>Note</label>
                <quillEditor v-model="note" :options="editorOption" />
              </v-col>
                 <v-col cols="12" class="pl-2">
              <v-checkbox
                v-model="early_check_in"
                label="Early Check In"
                :true-value=true
                :false-value=false
              ></v-checkbox>
               </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-10" v-if="this.event_category == 4">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitHotelForm()"
          >
            <span>Add Event</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>

        <v-card-text v-if="this.event_category == 7">
          <v-form lazy-validation ref="ptoForm">
            <v-row no-gutters>
              <v-col cols="12" class="pl-2">
                <v-autocomplete
                  label="Select Player"
                  outlined
                  dense
                  v-model="player"
                  :items="playerList"
                  :rules="[rules.required]"
                  item-value="id"
                  item-text="name"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu13"
                  v-model="menu13"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="ptoformatedStartDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Start Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      :rules="[rules.required]"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    no-title
                    @input="menu13 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu23"
                  v-model="menu23"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="ptoformatedEndDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="End Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      :rules="[rules.required]"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    no-title
                    @input="menu23 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <label style="font-size: 16px;font-weight: 500;">Start Time</label>
                   <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="start_time"
                  placeholder="Start Time"
                  close-on-complete
                  :rules="[rules.required]"
                     :manual-input="true"    :minute-interval="5"
                ></vue-timepicker>
              </v-col>
              <v-col cols="6" class="pl-2">
                <label style="font-size: 16px;font-weight: 500;">End Time</label>
                 <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="end_time"
                  placeholder="End Time"
                  close-on-complete
                  :rules="[rules.required]"
                     :manual-input="true"    :minute-interval="5"
                ></vue-timepicker>
              </v-col>
            </v-row>
            <br />
            <v-row no-gutters>
              <label>Note</label>
              <quillEditor v-model="note" :options="editorOption" />
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-10" v-if="this.event_category == 7">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitPTOForm()"
          >
            <span>Add Event</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'views'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitPTOForm()"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapActions, mapGetters } from "vuex";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import RULES from "@/common/fieldRules";
import {
  PAID_ASSEMBLY_SCHEDUAL_API_POST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_TEAM_NAME_LIST,
  API_MASTER_GET_STATES_LIST,
  API_EVENT_TEAM_PLAYER_LIST_GET,
  API_ADMIN_TEAM_CREATE_MANAGEMENT_GET,
  API_HOTEL_INFO_POST,
  API_CALENDAR_POST_TASK_DATA,
  PAID_ASSEMBLY_SCHEDUAL_API_DETAIL,
  PAID_ASSEMBLY_SCHEDUAL_API_PATCH,
  API_PAID_EVENT_POST_DATA,
  API_PAID_EVENT_PATCH_DATA,
  API_USER_GET_EVENT_SUPPORT_LIST,
  API_PAID_EVENT_GET_DATA,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
import Helper from "@/helper";
export default {
  name: "AddEventModal",
  components: { VueTimepicker, quillEditor },
  data() {
    return {
      rules: RULES,
       early_check_in:false,
      loading: false,
      formLoading: false,
      name: "",
      role: null,
      roleList: [],
      categoryList: [
        { id: 4, name: "Hotel" },
        { id: 7, name: "PTO" },
        { id: 8, name: "Custom" },
      ],
      designation: "",
      contact_name:"",
      menu: false,
      menu1: false,
      menu2: false,
      menu4: false,
      menu16:false,
      start_date: "",
      end_date: "",
      start_time: "08:00 AM",
      end_time: "08:00 AM",
      note: "",
      event_category: "",
      user_id: localStorage.getItem("user_id"),
      team_name: "",
      team_id: "",
      organization: "",
      sales_rep: "",
      event_support: "",
      address: "",
      meeting_time:"",
      email:"",
      event_name: "",
      event_name1: "",
      salesRepList: [],
      teamNameList: [],
      statesList: [],
      players: [],
      playerList: [],
      eventSupportList: [],
      player: "",
      city: "",
      state: "",
      menu11: false,
      menu21: false,
      hotelName: "",
      contactPersonName: "",
      mobile: null,
      contact_email: "",
      phone: "",
      noOfRooms: null,
      checkInDate: "",
      checkOutDate: "",
      hotel_address: "",
      confirmation_number: "",
      rate: "",
      menu13: false,
      menu23: false,
      school: "",
      eventNameOptions: [
        "Bar-Mitzvah",
        "Camp Game",
        "Camp Halfcourt",
        "Corporate",
        "Fair",
        "Family Night",
        "Paid Assembly",
        "SUMMER CAMP",
        "Virtual Assembly",
        "Travel Day",
        "Other",
      ],
      ptoDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEventModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.addEventModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Event Added";
      } else {
        return "Event Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Event";
        case "edit":
          return "Edit Event";
        case "view":
          return "Edit Custom Event";
        case "views":
          return "Edit PTO Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
      formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedPaidEventStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
      formatedPaidEventEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedStartTime: {
      get() {
        if (this.start_time) {
          return Helper.timeFormatter(this.start_time);
        } else {
          return "";
        }
      },
    },
    formatedEndTime: {
      get() {
        if (this.end_time) {
          return Helper.timeFormatter(this.end_time);
        } else {
          return "";
        }
      },
    },
    checkInDateFormatted: {
      get() {
        if (this.checkInDate) {
          return moment(this.checkInDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return null;
      },
    },
    checkOutDateFormatted: {
      get() {
        if (this.checkOutDate) {
          return moment(this.checkOutDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return null;
      },
    },
    ptoformatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    ptoformatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddEventModal: "calendarManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getStateList();
      this.getteamPlayer();
      this.getSalesRepList();
      this.getTeamList();
      this.getEventSupportList();
      if (this.type == "view") {
        this.getcustomeventDetail();
      }
      if (this.type == "views") {
        this.getptoDetail();
      }
      if (this.type == "edit") {
        this.getDetail();
      }
    },
    closeCallBackModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.role = null;
      this.roleList = [];
      this.playerList = [];
      this.$refs.teamForm.reset();
    },
    timeFormatter(time) {
      let timeInt = parseInt(time);
      let minutes = time.substring(3, 5);
      if (time.split(":")[0] == "00") return `12:${time.split(":")[1]} AM`;
      if (time >= "13:00") return `${timeInt - 12}:${minutes} PM`;
      else if (time >= "12:00") return `${timeInt}:${minutes} PM`;
      else return `${timeInt}:${minutes} AM`;
    },
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;
        self.toggleAddEventModal({ show: false });
        // self.$emit("reload");
        location.reload();
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["event_type"] = "Event";
      formData["event_category"] = this.event_category;
      formData["date"] = this.formatedStartDate;
      // formData["end_date"] = this.formatedEndDate;
      formData["owner"] = this.user_id;
      formData["start_time"] = Helper.convertTime12To24(this.start_time);
      formData["end_time"] = Helper.convertTime12To24(this.end_time);
      formData["address"] = this.address;
    
      formData["school"] = this.school;
      formData["team"] = this.team_id;
      formData["sales_rep"] = this.sales_rep;
      formData["event_support"] = this.event_support;
      formData["organization"] = this.organization;
      formData["designation"] = this.designation;
      formData["note"] = this.note;
      formData["assemble_status"] = "paid";
      formData["city"] = this.city;
      formData["state"] = this.state;
      this.ticketCopy = [];
      Object.keys(this.players).forEach((key) =>
        this.ticketCopy.push(this.players[key].id)
      );
      formData["players"] = this.ticketCopy;
      console.log(formData);
      if (this.type === "add") {
        Axios.request_POST(
          PAID_ASSEMBLY_SCHEDUAL_API_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        formData["id"] =
          this.$store.state.calendarManagement.addEventModal.eventID;
        Axios.request_PATCH(
          PAID_ASSEMBLY_SCHEDUAL_API_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },

    submitPaidEventForm() {
      if (this.$refs.teamForm.validate()) {
        const self = this;
        self.formLoading = true;
        const successHandler = () => {
          self.formLoading = false;
          self.toggleAddEventModal({ show: false });
          // self.$emit("reload");
       location.reload();
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };
        const failureHandler = (res) => {
          console.log(res.data);
          self.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["event_type"] = "Event";
        if (this.event_name == "Other") {
          formData["event_name"] = this.event_name1;
        } else {
          formData["event_name"] = this.event_name;
        }
        formData["event_category"] = this.event_category;
        formData["start_date"] = this.formatedPaidEventStartDate;
        if(this.end_date){
        formData["end_date"] = this.formatedPaidEventEndDate;
        }
        formData["owner"] = this.user_id;
        if (this.start_time) {
          formData["start_time"] = Helper.convertTime12To24(this.start_time);
        }
        if (this.end_time) {
          formData["end_time"] = Helper.convertTime12To24(this.end_time);
        }
        formData["address"] = this.address;
       if (this.meeting_time) {
        formData["meeting_time"] = Helper.convertTime12To24(this.meeting_time);
      }
      formData["email"] = this.email;
        formData["school"] = this.school;
        this.ticketCopy = [];
        Object.keys(this.players).forEach((key) =>
          this.ticketCopy.push(this.players[key].id)
        );
        if (this.team_id) {
          formData["team"] = this.team_id;
          formData["players"] = [];
        } else {
          formData["team"] = null;
          formData["players"] = this.ticketCopy;
        }
        // formData["team"] = this.team_id;
        formData["sales_rep"] = this.sales_rep;
        formData["event_support"] = this.event_support;
        formData["organization"] = this.organization;
        formData["designation"] = this.designation;
        formData["note"] = this.note;
        formData["city"] = this.city;
        formData["state"] = this.state;
         formData["contact_name"] = this.contact_name;
         formData["phone"] = this.phone;

        console.log(formData);
        if (this.type === "add") {
          Axios.request_POST(
            API_PAID_EVENT_POST_DATA,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.calendarManagement.addEventModal.eventID;
          Axios.request_PATCH(
            API_PAID_EVENT_PATCH_DATA,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    submitHotelForm() {
      if (this.$refs.hotelInfoForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          location.reload();
          this.showToast({
            message: "Updated successfully.",
            color: "s",
          });
          this.formLoading = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.showToast({
            message: res,
            color: "e",
          });
          this.formLoading = false;
        };
        let formData = {};
        formData["name"] = this.hotelName;
        formData["contact_person_name"] = this.contactPersonName;
        formData["contact_email"] = this.contact_email;
        formData["phone"] = this.phone;
        formData["number_of_rooms"] = this.noOfRooms;
        formData["hotel_address"] = this.hotel_address;
        formData["confirmation_number"] = this.confirmation_number;
         formData["early_check_in"] = this.early_check_in;
        formData["rate"] = this.rate;
        formData["checking_in_date"] = this.checkInDateFormatted;
        formData["checking_out_date"] = this.checkOutDateFormatted;
        formData["note"] = this.note;
        formData["city"] = this.city;
        formData["state"] = this.state;
        this.ticketCopy = [];
        Object.keys(this.players).forEach((key) =>
          this.ticketCopy.push(this.players[key].id)
        );

        if (this.team_id) {
          formData["team"] = this.team_id;
          formData["players"] = [];
        } else {
          formData["team"] = null;
          formData["players"] = this.ticketCopy;
        }

        if (this.type === "add") {
          Axios.request_POST(
            API_HOTEL_INFO_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    getEventSupportList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.eventSupportList = data.event_support_staff_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    submitPTOForm() {
      const self = this;
      if (this.$refs.ptoForm.validate()) {
        self.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          location.reload();
          this.showToast({
            message: "Updated successfully.",
            color: "s",
          });
          this.formLoading = false;
        };
        const failureHandler = (res) => {
          console.log(res.data);
          self.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["event_type"] = "Pto";
        formData["event_category"] = 7;
        formData["start_date"] = this.ptoformatedStartDate;
        formData["end_date"] = this.ptoformatedEndDate;
        if (this.start_time) {
          formData["start_time"] = Helper.convertTime12To24(this.start_time);
        }
        if (this.end_time) {
          formData["end_time"] = Helper.convertTime12To24(this.end_time);
        }
        formData["owner"] = this.user_id;
        formData["note"] = this.note;
        formData["player"] = this.player;
        console.log(formData);
        if (this.type === "add") {
          Axios.request_POST(
            API_CALENDAR_POST_TASK_DATA,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.calendarManagement.addEventModal.eventID;
          Axios.request_PATCH(
            "/calendar/" +
              this.$store.state.calendarManagement.addEventModal.eventID +
              "/",
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    getSalesRepList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.salesRepList = data.sales_rep_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getStateList() {
      const self = this;
      const successHandler = function (res) {
        const data = res.data;
        console.log(data.state_list);
        self.statesList = data.state_list;
      };
      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = function () {};
      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getTeamList() {
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        this.teamNameList = teamlistsss.concat(teamnaeee);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSalesRepList();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamPlayers() {
      const self = this;
      self.loading = true;

      const successHandler = (result) => {
        const data = result.data;
        console.log("dasdasdas", data.result.Player_list);

        self.playerList = data.result.Player_list;
        console.log("###", self.playerList);
        self.loading = false;
      };
      const failureHandler = (result) => {
        console.log(result.data);
        self.loading = false;
      };
      const finallyHandler = function () {
        console.log("in finally");
        if (self.type === "edit") {
          // self.getTeamDetail();
        }
      };

      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_ADMIN_TEAM_CREATE_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    checkbox(id) {
      this.players.push(id);
    },
    getDetail() {
      const successHandler = (res) => {
        //this.starttimer()
        console.log(res);
        this.event_category = 6;
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.school = res.data.assemble_detail.school;
        this.start_date = res.data.assemble_detail.date;
       
        this.start_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.start_time
        );
        this.end_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.end_time
        );
        this.state = res.data.assemble_detail.state;
        this.city = res.data.assemble_detail.city;
        this.event_support = res.data.assemble_detail.event_support;
        this.organization = res.data.assemble_detail.organization;
        this.designation = res.data.assemble_detail.designation;
       
        this.sales_rep = res.data.assemble_detail.sales_rep;
        this.note = res.data.assemble_detail.note;
        this.team_id = res.data.assemble_detail.team;
        if (res.data.assemble_detail.player_list != null) {
          let playerIdList = res.data.assemble_detail.player_list.map(function (
            obj
          ) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] =
        this.$store.state.calendarManagement.addEventModal.eventID;
      Axios.request_GET(
        PAID_ASSEMBLY_SCHEDUAL_API_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getcustomeventDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.customDetail = res.data.paid_event_detail;
        var customdisplaydatess = new Date(this.customDetail.date);
        var customdate = new Date(customdisplaydatess);
        this.customdisplayDates = customdate.toDateString();
        this.event_category = 8;
        this.event_name = this.customDetail.event_name;
        this.school = this.customDetail.school;
        this.address = this.customDetail.address;
        this.school = this.customDetail.school;
        this.start_date = this.customDetail.start_date;
          this.end_date = this.customDetail.end_date;
          this.email = this.customDetail.email;
        if(this.customDetail.meeting_time){
         this.meeting_time = Helper.convertFrom24To12Format(
          this.customDetail.meeting_time
        );
        };
        this.start_time = Helper.convertFrom24To12Format(
          this.customDetail.start_time
        );
        this.end_time = Helper.convertFrom24To12Format(
          this.customDetail.end_time
        );
        this.state = this.customDetail.state;
        this.city = this.customDetail.city;
        this.event_support = this.customDetail.event_support;
        this.organization = this.customDetail.organization;
        this.designation = this.customDetail.designation;
        this.sales_rep = this.customDetail.sales_rep;
        
        this.note = this.customDetail.note;
          this.contact_name = this.customDetail.contact_name;
          this.phone = this.customDetail.phone;
        this.team_id = this.customDetail.team;
        if (this.customDetail.player_list != null) {
          let playerIdList = this.customDetail.player_list.map(function (obj) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["paid_event_id"] =
        this.$store.state.calendarManagement.addEventModal.eventID;
      Axios.request_GET(
        API_PAID_EVENT_GET_DATA,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getptoDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ptoDetail = res.data.calendar_detail;
        this.event_category = 7;
        this.start_date = this.ptoDetail.start_date;
        this.end_date = this.ptoDetail.end_date;
        this.player = this.ptoDetail.player;
        if (this.ptoDetail.note) {
          this.note = this.ptoDetail.note;
        }
        this.start_time = Helper.convertFrom24To12Format(
          this.ptoDetail.start_time
        );
        this.end_time = Helper.convertFrom24To12Format(this.ptoDetail.end_time);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        "/calendar/" +
          this.$store.state.calendarManagement.addEventModal.eventID +
          "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    starttimer() {
      (this.categoryList = [{ id: 6, name: "Assembly" }]),
        setTimeout(() => {
          const elem = document.getElementById("addplayer");
          elem.click();
        }, 1);
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
